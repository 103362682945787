import React from 'react'
import { Row, Col } from 'antd'

interface DocsFooterProps {
    filename: string
    title: string
}

export function DocsFooter({ filename, title }: DocsFooterProps) {
    return (
        <div className="docs-footer" id="docs-footer">
            <Row>
                <h3>Reach Out</h3>
                If you need help on any of the above, feel free to <a href="/discord">join our Discord</a> server where
                a member of our team can assist you! Chances are that if you have a problem or question, someone else
                does too - so please don't hesitate to ask us a question. <br />
                <br />
                {filename && title}
                {/*
                <Col span={12}>
                    <strong>Docs</strong>
                    <br />
                    <a href={'https://github.com/PostHog/posthog.com/tree/master/contents' + filename}>
                        Edit this page
                    </a>
                    <br />
                    <a
                        href={
                            'https://github.com/PostHog/posthog.com/issues/new?title=Docs feedback on: ' +
                            title +
                            '&body=**Issue with: ' +
                            filename +
                            '**\n\n'
                        }
                    >
                        Raise an issue
                    </a>{' '}
                    about this page
                    <br />
                    </Col>*/}
                <Col span={12}>
                    <strong>Community and support</strong>
                    <br />
                    Join our <a href="/discord">Discord community</a>
                    <br />
                    <br />
                    Email us at <a href="mailto:hey@aiprentice.com">hey@aiprentice.com</a>
                    <br />
                </Col>
            </Row>
        </div>
    )
}
